<template>
  <div>
    <update-channel-aside
      v-if="aside === 'update'"
      :active="true"
      :resource="channel"
      @changed="loadChannels(); aside = false"
      @aside="handleAside"
    ></update-channel-aside>

    <create-channel-aside
      v-if="aside === 'create'"
      :active="true"
      @changed="loadChannels(); aside = false"
      @aside="handleAside"
    ></create-channel-aside>

    <v-card>
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="aside = 'create'"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add a new channel</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.name`]="{item}">
          <span>{{ t(item.name) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setItem(item, 'update')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import CreateChannelAside from '../channel-resource/CreateChannelAside.vue'
import UpdateChannelAside from '../channel-resource/UpdateChannelAside.vue'

// composition function
import useChannelList from './useChannelList'

export default {
  components: { CreateChannelAside, UpdateChannelAside },

  filters: { price, dateTime },

  setup() {
    const {
      listTable, searchQuery, tableColumns, options, statusFilter, totalListTable, loading, selectedTableData, loadChannels,
    } = useChannelList()

    const aside = ref(false)
    const channel = ref(null)

    const setItem = (item, action = 'channel') => {
      channel.value = item
      aside.value = action
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        channel.value = false
        loadChannels()
      }
    }

    return {
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      t,

      aside,
      channel,
      setItem,
      handleAside,
      loadChannels,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiPlus,
      },
    }
  },
}
</script>

  <style lang="scss" scoped>
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
  </style>
