import { fetchChannels } from '@/api/channel'
import { useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useChannelList() {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'CODE', value: 'code', sortable: true },
    { text: 'TYPE', value: 'type', sortable: true },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadChannels = () =>
    fetchChannels(
      useTableOptions(options.value, {
        search: searchQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        listTable.value = records
        totalListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  loadChannels()

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadChannels()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    options,
    listTable,
    totalListTable,
    loading,
    selectedTableData,
    loadChannels,
  }
}
