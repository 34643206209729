<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text class="mt-4">
        <translatable-input
          v-model="form.name"
          label="A easy recognizable Name."
          outlined
          :rules="[required]"
        ></translatable-input>

        <v-text-field
          v-model="form.code"
          label="Channel Code"
          placeholder="PWC"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-select
          v-model="form.type"
          label="Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
          hide-details
        ></v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      type: props.resource?.type || 'white_label',
      code: props.resource?.code || '',
      name: useTranslatable(props.resource, 'name'),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const typeOptions = [
      { title: 'API', value: 'api' },
      { title: 'White Label', value: 'white_label' },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      typeOptions,
    }
  },
}
</script>
